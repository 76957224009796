import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Content from 'components/category/content'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/construction/track-loaders/compact-track-loaders/',
    imageId: 'trackLoadersImage',
    name: 'Compact Track Loaders',
    alt: 'John Deere Compact Track Loaders',
  },
]

const TrackLoadersPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Track Loaders | Hutson Inc</title>
        <meta
          name='description'
          content='Compare and shop the John Deere G-Series skid steer. Huge uptimes plus tons of attachments that can make your work a lot easier. Checkout specs and other models.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Construction Equipment',
                'item': 'https://www.hutsoninc.com/construction/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Track Loaders',
                'item': 'https://www.hutsoninc.com/construction/track-loaders/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Track Loaders' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Track Loader' />

        <Videos type='Track Loader' id='C7VffaNxHpA' list='PLUz5fj7f_mw91fyFTo5_GNdzmaW4IW0tP' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query trackLoadersSubcategoryQuery($subcategory: String = "track-loaders") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/compact-track-loaders-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    trackLoadersImage: file(relativePath: { eq: "construction/track-loaders.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default TrackLoadersPage
